import React from 'react';
import { Link } from 'gatsby';

import { BlogPostOverview } from '../model';
import { removeHTMLTags } from '../utils';

interface BlogPostsListProps {
  blogPosts: ReadonlyArray<BlogPostOverview>;
  emptyText?: string;
}

export const BlogPostsList = ({
  blogPosts,
  emptyText = 'Keine Antworten',
}: BlogPostsListProps) => {
  return (
    <ul className="list-none space-y-6 divide-y-2 divide-slate-100">
      {blogPosts.length > 0 ? (
        blogPosts.map((blogPost) => (
          <li key={blogPost.id} className="pt-6">
            <Link
              to={blogPost.meta.urlAlias}
              className="font-semibold antialiased hover:text-pink after:content-['_→'] after:text-pink"
            >
              {removeHTMLTags(blogPost.question).substring(0, 140)}
              {blogPost.question.length > 140 && '...'}
            </Link>
          </li>
        ))
      ) : (
        <li className="pt-6 text-center">
          <span className="font-medium text-sm antialiased">{emptyText}</span>
        </li>
      )}
    </ul>
  );
};
