import React from 'react';

import { BlogPostOverview } from '../model';
import { BlogPostsList } from '../components/BlogPostsList';

interface TaxonomyViewProps {
  term: string;
  blogPosts: ReadonlyArray<BlogPostOverview>;
}

export const TaxonomyView = ({ term, blogPosts }: TaxonomyViewProps) => {
  return (
    <div>
      <h1 className="text-xl font-semibold">
        {term} ({blogPosts.length})
      </h1>
      <main>
        <BlogPostsList blogPosts={blogPosts} />
      </main>
    </div>
  );
};
